<template>
  <div class="video-output-selector">
    <div class="loading-container" v-if="loadPresetState.status === 'loading'">
      <i class="fas fa-circle-notch fa-spin fa-4x" />
    </div>
    <div class="loading-container" v-if="loadPresetState.status === 'error'">
      {{t('response:loadingFailed.title')}}
    </div>
    <div class="options-container" v-if="loadPresetState.status === 'success'">
      <div class="form-radio" v-for="(preset, index) in presets" :key="preset.id">
        <RadioButtonChoice
          :modelValue="isCurrentPreset(preset)"
          :data="preset.id"
          @update:data="onSelectedPreset"
          :dataTest="`preset-radio-button-${index}`"
        >
          <div class="preset-name">
            {{preset.name}}
          </div>
          <div
            class="preset-profile-wrapper"
            v-for="profile in preset.profiles"
            :key="profile.id">
            <PresetSummaryInfo :profile="profile" />
          </div>
        </RadioButtonChoice>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { onMounted, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import { type Preset, loadPresets, PresetSummaryInfo } from '@/modules/preset';

interface VideoOutputSelectorProps {
  presetData: Preset;
}

const props = defineProps<VideoOutputSelectorProps>();
const { presetData } = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: Preset | null];
}>();

const { t } = useI18n();

const presets = ref<Array<Preset>>([]);
const selectedPreset = ref<Preset | null>(null);
const loadPresetState = ref<{ status: string; errorCode: number | null }>({
  status: 'idle',
  errorCode: null,
});

async function load(filter: Record<string, string>) {
  loadPresetState.value.status = 'loading';
  try {
    const response = await loadPresets(filter);
    presets.value = response.data.data;
    loadPresetState.value.status = 'success';
  } catch (error) {
    loadPresetState.value.errorCode = _.get(error, 'response.status', 500);
    loadPresetState.value.status = 'error';
  }
}

function isCurrentPreset(preset: Preset) {
  const currentPresetId = _.get(selectedPreset.value, 'id', null);
  return preset.id === currentPresetId;
}

function onSelectedPreset(presetId: string) {
  selectedPreset.value = presets.value.find((p) => p.id === presetId) ?? null;
  emit('update:modelValue', selectedPreset.value);
}

onMounted(() => {
  load({ limit: '100' });
  selectedPreset.value = _.cloneDeep(presetData.value);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers.scss';

.video-output-selector {
  height: 500px;
  overflow-y: scroll;
  @extend .scrollbar;
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-radio {
  margin-bottom: $spacing-base;
  .preset-name {
    color: $grey-700;
    //margin-bottom: $spacing-base * 0.5;
    font-weight: $font-weight-bold;
  }
  .preset-profile-wrapper {
    margin-top: $spacing-base;
    margin-bottom: $spacing-base * 0.5;
  }
}
</style>
