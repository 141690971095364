<template>
  <div class="data-table-container" :data-test="dataTest">
    <div class="title-container" v-if="title || subtitle">
      <h5 class="title" v-if="title">
        {{title}}
      </h5>
      <div class="subtitle" v-if="subtitle">
        {{subtitle}}
      </div>
    </div>
    <div class="action-container" v-if="onSearchData || $slots['table-action']">
      <div class="search-input-container" v-if="onSearchData">
        <Input
          dataTest="input-data-table"
          @change="onSearchData"
          :onPressedEnter="onSearchData"
          :isSearchInput="isSearchInput"
          :modelValue="searchInputValue"
          :placeholder="searchInputPlaceholder || t('common:form.searchBy')" />
      </div>
      <div class="table-action-container">
        <slot name="table-action" />
      </div>
    </div>
    <div class="table-tab-container" v-if="$slots['table-tab']">
      <slot name="table-tab" />
    </div>
    <div class="selected-container" v-if="selectedItemsCount">
      <p>{{ selectedItemsCount }} selected</p>
      <Button
        buttonStyle="secondary"
        @click.stop="emit('select-action', true)"
      >
        <template v-slot:icon-prefix>
          <i :class="actionButtonIconClassName" />
        </template>
        <template v-slot:content>
          {{ actionButtonText }}
        </template>
      </Button>
    </div>
    <div
      class="table"
      :class="responsiveLayout ? `responsive-layout-${responsiveLayout}` : ''"
      v-if="$slots['table-header'] || $slots['table-content'] || $slots['table-footer']">
      <div class="table-header" v-if="$slots['table-header']" :class="responsiveLayout ? `responsive-layout-${responsiveLayout}` : ''">
        <slot name="table-header" />
      </div>
      <div class="table-content" :class="responsiveLayout ? `responsive-layout-${responsiveLayout}` : ''"  v-if="$slots['table-content']">
        <slot name="table-content" />
      </div>
    </div>
    <div class="table-footer-container" v-if="$slots['table-footer']">
      <slot name="table-footer" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface DataTableProps {
  title?: string;
  subtitle?: string;
  searchInputPlaceholder?: string;
  searchInputValue?: string;
  isSearchInput?: boolean;
  onSearchData?: (event: KeyboardEvent) => void;
  responsiveLayout?: 'a' | 'b' | 'c' | 'draggable';
  selectedItemsCount?: number;
  actionButtonText?: string;
  actionButtonIconClassName?: string;
  dataTest?: string;
}

const props = withDefaults(defineProps<DataTableProps>(), {
  isSearchInput: true,
  searchInputValue: '',
});

const {
  title,
  subtitle,
  searchInputPlaceholder,
  searchInputValue,
  isSearchInput,
  onSearchData,
  responsiveLayout,
  selectedItemsCount,
  actionButtonText,
  actionButtonIconClassName,
  dataTest,
} = toRefs(props);

const emit = defineEmits<{
  'select-action': [value: boolean];
}>();

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.data-table-container {
  overflow: hidden;
  /* position: relative; */
}

.title-container {
  margin-bottom: $spacing-24;
}

.action-container {
  display: flex;
  margin-bottom: $spacing-24;
}

.search-input-container {
  max-width: 400px;
  flex: 1;

  &:not(:last-child) {
    margin-right: $spacing-base;
  }
}

.table {
  overflow: auto;
}

.table-action-container {
  display: flex;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: $spacing-12;
  }
}

.table-tab-container {
  margin: $spacing-24 0;
}

.selected-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-12;
}

.table {
  border: 1px solid $grey-200;
  border-radius: 5px;
}

.table-header  {
  background: $grey-100;
  min-width: fit-content;
  :deep(.row) {
    background: $grey-100;
    cursor: default;
  }
  :deep(.column) {
    padding: $spacing-8 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $spacing-base;
    min-height: unset;

    &:has(.is-enable-sort) {
      cursor: pointer;
    }
  }
}

.table-content {
  background-color: $white;
  min-width: fit-content;

  :deep(.primary-column) {
    color: $grey-800;
    font-weight: 500;
  }
}

.table-footer-container {
  margin-top: $spacing-24;
}

.responsive-layout-draggable {
  :deep(.row) {
    transition: background .2s ease-in-out;
  }
}

@media screen and (max-width: $max-layout-md) {
  .data-table-container .table.responsive-layout-draggable {
    & {
      overflow: visible !important;
    }
  }
  .table {
    overflow: auto;
  }
  .table-header > :deep(.row .column) {
    background: $grey-100 !important;
  }
  .responsive-layout-a {
    :deep(.row) {
      min-width: fit-content;
      .column {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
      }
    }
  }

  .responsive-layout-b {
    :deep(.row) {
      padding: 0;
      min-width: fit-content;
      align-items: unset !important;
      .column:first-child {
        position: sticky;
        overflow: hidden;
        left: 0px;
        padding-right: $spacing-8;
        border-right: 1px solid $grey-200;
        background: white;
        height: auto;
        z-index: 5;
      }
      .column {
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1 1 100%;
        min-width: 160px;
        margin: 0;
        padding-left: $spacing-base;

        &:first-child {
          max-width: 160px;
        }

        &:hover > :deep(*) {
          background: $grey-100;
        }
      }
    }
    .table-content :deep(.column) {
      min-height: 58px;
      padding-top: 20px;
      padding-bottom: 12px;

      &.action-column {
        min-height: 36px;
        padding-top: 12px;
      }
    }
  }
  .responsive-layout-c {
    .data-table-container & {
      overflow: visible;
    }
    .table-header {
      :deep(.row) {
        > :not(:first-child) {
          display: none;
        }
      }
    }
    .table-content {
      :deep(.column) {
        width: calc(100% - 50px);
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-height: unset;

        &:first-child {
          padding-top: 0;
          align-self: center;
          /* padding-bottom: $spacing-12; */

          // remove column width
          width: fit-content !important;
          min-width: unset !important;
        }

        &:nth-child(2) {
          padding-top: $spacing-12;

          // remove column width
          width: fit-content !important;
          min-width: unset !important;
        }

        &:nth-child(n + 3) {
          display: none;
        }

        &.action-column {
          display: none;
        }
        .primary-text {
          word-wrap: break-word;
        }
      }

      :deep(.row) {
        padding: $spacing-12 0;

        // change row to grid
        position: static;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: auto;

        &:not(:last-child) {
          border-bottom: 0.5px solid $grey-200;
        }

        &.with-responsive-icon {
          > :nth-last-child(2) {
            padding-right: 0;
          }
        }
        .responsive-c-button {
          display: flex;

          // place icon to column 2 row 1 and span 2 rows
          position: static;
          grid-column: 2;
          grid-row: 1 / -1;
          margin-right: $spacing-base;
          align-self: flex-start;
        }
      }
    }
    :deep(.row) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
  }
  .responsive-layout-draggable {
    .table-header {
      :deep(.row) {
        > :not(:nth-child(2)) {
          display: none;
        }
      }
    }

    .table-content {
      :deep(.column) {
        // 100% - grid icon width
        width: calc(100% - 48px);
        margin-left: $spacing-base;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        min-height: unset;

        &:first-child {
          display: none;
        }

        &:nth-child(2) {
          grid-row: 1;
          grid-column: 2;

          // remove column width
          width: fit-content !important;
          min-width: unset !important;
        }

        &:nth-child(3) {
          margin-top: $spacing-8;
          grid-row: 2;
          grid-column: 2;

          // remove column width
          width: fit-content !important;
          min-width: unset !important;
        }

        &:nth-child(n + 4) {
          display: none;
        }

        &.action-column {
          display: none;
        }
      }

      :deep(.row) {
        /* margin: $spacing-12 0; */
        padding: $spacing-12 0;

        // change row to grid
        position: static;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto auto;
        width: 100%;
        &.with-responsive-icon {
          .column {
            // 100% - grid icon width - action container width
            /* width: calc(100% - 112px); */
          }
        }
      }
    }

    :deep(.row) {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      position: relative;
      transition: background .2s ease-in-out;

      &.with-responsive-icon {
        > :nth-last-child(2) {
          padding-right: 0;
        }
      }

      &:not(:last-child) {
        border-bottom: 0.5px solid $grey-200;
      }

      .draggable-icon-container {
        display: flex;

        // place icon to column 1 row 1 and span 2 rows
        position: static;
        grid-column-start: 1;
        grid-row: 1 / span 2;
        align-self: center;
        justify-self: center;
        margin-left: $spacing-base;
      }
      .responsive-draggable-icon {
        display: flex;

        // place icon to column 3 row 1 and span 2 rows
        position: static;
        grid-column-start: 3;
        grid-row: 1 / span 2;
        margin-right: $spacing-base;
        align-self: flex-start;
      }
    };
  }
};

@media screen and (max-width: $max-layout-sm) {
  .action-container {
    flex-direction: column;
    .search-input-container {
      max-width: unset;
    }
    .component-container.text {
      max-width: unset;
    }
  }

  .search-input-container {
    margin-right: 0 !important;
  }

  .table-action-container {
    /* margin-left: 0; */
    margin-top: $spacing-base;
  }
}
</style>
