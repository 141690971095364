import type { RouteRecordRaw } from 'vue-router';
import { collectionList } from '@/modules/collectionList';
import { geoblockRuleList } from '@/modules/geoblock';
import { projectList } from '@/modules/projectList';
import { projectVideoCaptionIssueList, projectVideoList } from '@/modules/projectVideoList';
import videoUpload from '@/modules/videoUpload/store/videoUpload';
import videoUploaderDraggable from '@/modules/videoUpload/store/videoUploaderDraggable';
import store from '@/store';

export function createProjectVideoListRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'ProjectVideoListChrome',
      path: 'projects/:projectKey',
      component: () => import(/* webpackChunkName: "VideoUploaderChrome" */ '../layouts/ProjectVideoListChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        if (!store.hasModule('projectVideoCaptionIssueList')) {
          store.registerModule('projectVideoCaptionIssueList', projectVideoCaptionIssueList);
        }
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('videoUpload')) {
          store.registerModule('videoUpload', videoUpload);
        }
        if (!store.hasModule('geoblockRuleList')) {
          store.registerModule('geoblockRuleList', geoblockRuleList);
        }
        if (!store.hasModule('videoUploaderDraggable')) {
          store.registerModule('videoUploaderDraggable', videoUploaderDraggable);
        }
        next();
      },
      children: [
        {
          name: 'projectVideos',
          path: '',
          component: () => import(/* webpackChunkName: "ProjectVideos" */ '../pages/ProjectVideos.vue'),
        },
        {
          path: 'video-issues',
          name: 'projectVideoIssues',
          component: () => import(/* webpackChunkName: "ProjectVideoBadWordList" */ '../pages/ProjectVideoBadWordList.vue'),
        },
        // <!-- [PDBY-571]-->
        // {
        //   name: 'projectVideoIssues',
        //   path: 'video-issues',
        //   component: () => import(/* webpackChunkName: "projectVideoIssues" */ '../pages/ProjectVideoIssues.vue'),
        // },
        {
          name: 'recentlyDeleted',
          path: 'recently-deleted',
          component: () => import(/* webpackChunkName: "ProjectRecentlyDeletedVideo" */ '../pages/ProjectRecentlyDeletedVideo.vue'),
        },
      ],
    },
  ];
}
