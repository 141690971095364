<template>
  <div class="step-container step-introduce" :style="{ minHeight: minHeight }">
    <i v-if="iconClass" :class="iconClass" />
    <h5 v-if="computedTitle">{{ computedTitle }}</h5>
    <p v-if="computedDescription" v-html="computedDescription" />
    <div class="button-container">
      <div
        class="button-wrapper"
        v-if="isButtonCanShow(computedPrimaryButtonTitle, computedPrimaryButtonAction)"
      >
        <Button
          type="button"
          buttonStyle="primary"
          isFullWidth
          buttonClasses="primary-action-button"
          @click.stop="computedPrimaryButtonAction"
        >
          <template v-slot:content>
            {{ computedPrimaryButtonTitle }}
          </template>
        </Button>
      </div>
      <div
        class="button-wrapper"
        v-if="isButtonCanShow(computedSecondaryButtonTitle, computedSecondaryButtonAction)"
      >
        <Button
          type="button"
          buttonStyle="text-primary"
          isFullWidth
          buttonClasses="secondary-action-button"
          @click.stop="computedSecondaryButtonAction"
        >
          <template v-slot:content>
            {{ computedSecondaryButtonTitle }}
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    iconClass: {
      type: String,
    },
    minHeight: {
      type: String,
      default: '300px',
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    primaryButtonTitle: {
      type: String,
    },
    primaryButtonAction: {
      type: [Function, String],
    },
    secondaryButtonTitle: {
      type: String,
    },
    secondaryButtonAction: {
      type: [Function, String],
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    teamName() {
      return this.$route.params.teamName;
    },
    computedTitle() {
      if (typeof this.title === 'string') {
        return this.title;
      }
      return this.$t('signedURL.urlSigningKey.notFound.title');
    },
    computedDescription() {
      if (typeof this.description === 'string') {
        return this.description;
      }
      return this.$t('signedURL.urlSigningKey.notFound.description');
    },
    computedPrimaryButtonTitle() {
      if (typeof this.primaryButtonTitle === 'string') {
        return this.primaryButtonTitle;
      }
      return this.$t('signedURL.urlSigningKey.notFound.create');
    },
    computedSecondaryButtonTitle() {
      if (typeof this.secondaryButtonTitle === 'string') {
        return this.secondaryButtonTitle;
      }
      return null;
    },
    computedPrimaryButtonAction() {
      if (typeof this.primaryButtonAction === 'function') {
        return this.primaryButtonAction.bind(this);
      }
      return () => {
        this.$router.push(this.primaryButtonAction);
      };
    },
    computedSecondaryButtonAction() {
      if (typeof this.secondaryButtonAction === 'function') {
        return this.secondaryButtonAction.bind(this);
      }
      return () => {
        this.$router.push(this.secondaryButtonAction);
      };
    },
  },
  methods: {
    isButtonCanShow(title, action) {
      return title && action;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers';

.step-container {
  display: flex;
  flex-direction: column;

  .icon {
    font-size: $font-size-base * 5;
    color: $grey-400;
    margin-bottom: $spacing-base;

    &.warning {
    }
  }

  .subtitle {
    font-size: $font-size-base * 1.125;
    padding: 0 $spacing-base * 0.5;
    margin-bottom: $spacing-base * 2;
  }

  .button-container {
    margin-top: auto;

    .button-wrapper {
      width: 100%;

      :deep(.primary-action-button) {
        margin-bottom: $spacing-base;
        margin-top: $spacing-base * 0.5;
      }
    }
  }
}
</style>
