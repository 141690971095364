import store from '@/store';
import project from '@/modules/shared/store/project';
import type { RouteRecordRaw } from 'vue-router';

export function createProjectDetailsRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'general',
      path: 'general',
      component: () => import(/* webpackChunkName: "ProjectDetails" */ '../pages/ProjectDetails.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        next();
      },
    },
  ];
}
