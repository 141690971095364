import type { VideoBumperContentsObject } from '@/modules/bumper/types';
import type { Video, VideoTag } from '@/modules/shared/types/video.type';
import type { VideoExport } from '@/modules/videoDetail/types/videoExport.type';
import { axiosAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import type { VideoCustomField } from '@/modules/customField';
import type { CancelToken } from 'axios';
import qs, { IStringifyOptions } from 'qs';

const defaultVideoFilter = {
  includes: [
    'project',
    'contentCollection',
    'player',
    'tags',
    'creator',
    'vod.source',
    'vod.storage',
    'bumperDisplay',
  ],
};

const defaultExportStorageQuery = {
  includes: [
    'accessKeyId',
    'secretAccessKey',
  ],
};

const qsOption: IStringifyOptions = {
  addQueryPrefix: true,
  skipNulls: true,
  arrayFormat: 'comma',
  encode: false,
};

export function loadVideoByKey(videoKey: string, filter?: Filter) {
  const query = qs.stringify({ ...defaultVideoFilter, ...filter }, qsOption);
  return axiosAPI.get<Video>(`/videos/${videoKey}${query}`);
}

export function loadVideoProbedResult(videoKey: string, projectKey: string) {
  return axiosAPI.get(`/projects/${projectKey}/videos/${videoKey}/probe`);
}

export function editVideos(videoKey: string, payload: Video) {
  return axiosAPI.put<Video>(`/videos/${videoKey}`, payload);
}

export function loadVideoTags({ projectKey, q }: { projectKey: string; q: string }) {
  const query = qs.stringify({ projectKey, q }, qsOption);
  return axiosAPI.get<ListApiResponse<VideoTag>>(`/video-tags${query}`);
}

export function createTag(projectKey: string, payload: unknown) {
  return axiosAPI.post(`/projects/${projectKey}/video-tags`, payload);
}

export function deleteVideoByKey(videoKey: string) {
  return axiosAPI.delete(`/videos/${videoKey}`);
}

export function loadVideoScreenShot(videoKey: string) {
  return axiosAPI.get(`/videos/${videoKey}/snapshot`);
}

export function getEmbeddedURL(videoKey: string) {
  return axiosAPI.get(`/videos/${videoKey}/playable-embedded-url`);
}

export function updateCustomFields(videoKey: string, payload: { customFields: Array<VideoCustomField> }) {
  return axiosAPI.put<Video>(`/videos/${videoKey}/custom-fields`, payload);
}

export function loadCustomImageByVideoKey(videoKey: string) {
  return axiosAPI.get(`/videos/${videoKey}/custom-image-entries`);
}

export function uploadImageToStorage(projectKey: string, payload: unknown, onUploadProgress: (progressEvent: ProgressEvent) => void, cancelToken: CancelToken) {
  return axiosAPI.post(`/projects/${projectKey}/custom-images/upload`, payload, {
    onUploadProgress,
    headers: { 'Content-Type': 'multipart/form-data' },
    cancelToken,
  });
}

export function saveCustomImage(videoKey: string, payload: string) {
  return axiosAPI.put(`videos/${videoKey}/custom-image-entries`, payload);
}

export function deleteCustomImageEntryById(videoKey: string, imageId: string) {
  return axiosAPI.delete(`videos/${videoKey}/custom-image-entries/${imageId}`);
}

export function updateAudioOutputTracks(videoKey: string, payload: unknown) {
  return axiosAPI.put(`videos/${videoKey}/outputs-audio-language`, payload);
}

export function getVideoProcessingJobs(videoKey: string) {
  return axiosAPI.get(`videos/${videoKey}/jobs`);
}

export function loadVideoBumperContents(videoKey: string) {
  return axiosAPI.get<VideoBumperContentsObject>(`videos/${videoKey}/bumper-contents`);
}

export function loadVideoExportFileList(videoKey: string, revisionKey: string) {
  return axiosAPI.get<VideoExport[]>(`/videos/${videoKey}/vods/${revisionKey}/download`);
}

export function requestExportVideo(videoKey: string, revisionKey: string, payload: unknown) {
  return axiosAPI.post(`/videos/${videoKey}/vods/${revisionKey}/request-export`, payload);
}

export function loadVideoExportStorage(downloadVideoOutputId: string, filter: Filter) {

  const query = qs.stringify({ ...defaultExportStorageQuery, filter }, qsOption);

  return axiosAPI.get(`/download-video-outputs/${downloadVideoOutputId}/storage-for-export-videos${query}`);
}
