<template>
  <div>
    <ReUploadVideoSourceFileModal
      v-if="toggleReuploadModal"
      :video="uploadItem"
      :onClose="onReuploadVideoSourceFile"/>
    <div class="list-group-item"
         :class="[getCustomClassByState()]">
      <div class="detail">
        <div class="title">
          {{uploadItem.title}}
        </div>
        <div class="state-item state-queued"
             v-if="isqueued()">
          {{$t('video.uploader.enqueued')}}
        </div>
        <div class="state-item state-uploading"
             v-if="isUploading()">
          <div class="progress-container">
            <div class="progress-bar" :style="{ 'width': `${uploadProgress}%` }"></div>
          </div>
          <div class="progress-upload-container">
            <div class="size">
              {{ getUploadedSize()}} / {{ getTotalSize() }}
              ({{getUploadTime()}}/s)
            </div>
            <div class="percent">
              {{uploadProgress}}%
            </div>
          </div>
        </div>
        <div class="state-item state-uploaded"
             v-if="isUploaded()">
          <span
            class="probe-status state-upload"
            :class="{ 'text-danger': isVideoProbeError(uploadItem),
                      'warning-status': probeStatus === 'overtime' }"
            v-if="isDisplayProbeStatus">
            {{getProbeStatus(uploadItem)}}
          </span>
          <span v-if="!isDisplayProbeStatus">
            {{uploadProgress}}% uploaded
          </span>
        </div>
        <div class="state-item state-retry"
             v-if="isRetry()">
          <div class="spinner-border text-primary" role="state">
            <span class="sr-only"></span>
          </div>
          {{$t('video.uploader.failed.retrying', {item: uploadItem.remainingRetryDelay})}}
        </div>
        <div class="state-item state-failed"
             v-if="isFailed()">
          <span class="text-danger">{{$t('video.uploader.failed.helpText')}}</span>
        </div>
      </div>
      <div class="actions">
        <span v-if="canRemoveFile()" :title="$t('glossary:delete')">
          <i class="far fa-trash-can"></i>
        </span>
        <span v-if="canShowCompleteState()" :title="$t('video.uploader.success')">
          <i class="fas fa-circle-check text-success" />
        </span>
        <span v-if="probeStatus === 'checking'" :title="$t('video.uploader.success')">
          <i class="fa fa-circle-o-notch fa-spin text-primary" />
        </span>
        <span v-if="isFailed()" :title="$t('video.uploader.failed.button')" @click="onRetryUploadVideo">
          <i class="fas fa-rotate-right" />
        </span>
        <span v-if="isVideoProbeError(uploadItem) && !isVideoSourceUploading(uploadItem)"
              :title="$t('video.uploader.failed.button')" @click="onReuploadVideoSourceFile">
          <i class="fas fa-upload" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { prettyFileSize } from '@/modules/shared/utils/unitFormatter';
import {
  isVideoProbeError,
  isVideoSourceUploading,
  isVideoProcessedOverTime,
  isVideoProbeCompleted,
} from '@/modules/videoDetail/utils/videoManagement';
import ReUploadVideoSourceFileModal from '../../organisms/reUploadVideoSourceFileModal/ReUploadVideoSourceFileModal.vue';

export default {
  name: 'VideoUploadProgressItem',
  props: {
    uploadItem: {
      type: Object,
    },
  },
  components: {
    ReUploadVideoSourceFileModal,
  },
  data() {
    return {
      toggleReuploadModal: false,
      probeStatus: null,
    };
  },
  computed: {
    ...mapState({
      isDisplayProbeStatus: (state) => state.videoUpload.isDisplayProbeStatus,
      deadlineTime: (state) => state.videoUpload.deadlineTime,
    }),
    uploadingInfo() {
      return this.uploadItem.uploadingInfo;
    },
    uploadProgress() {
      return _.get(this.uploadingInfo, 'progress', 0);
    },
  },
  methods: {
    prettyFileSize,
    isVideoProbeError,
    isVideoSourceUploading,
    isVideoProcessedOverTime,
    isVideoProbeCompleted,
    getTotalSize() {
      const totalSize = _.get(this.uploadingInfo, 'totalSize', 0);
      return this.prettyFileSize(totalSize);
    },
    getUploadedSize() {
      const uploadedSize = _.get(this.uploadingInfo, 'uploadedSize', 0);

      if (uploadedSize > 0) {
        return this.prettyFileSize(uploadedSize);
      }
      return 0;
    },
    getUploadTime() {
      const uploadedSize = _.get(this.uploadingInfo, 'uploadedSize', 0);
      const uploadTimeStamp = _.get(this.uploadingInfo, 'differenceTime', 0);

      if (uploadedSize && uploadTimeStamp) {
        const uploadTimeSecond = ((uploadTimeStamp) / 1000);
        const size = uploadedSize / uploadTimeSecond;
        return this.prettyFileSize(size);
      }

      return 0;
    },
    getCustomClassByState() {
      switch (this.uploadingInfo.state) {
        case 'queued':
          return 'list-group-item-queued';
        case 'retry':
          return 'list-group-item-retry';
        default:
          return '';
      }
    },
    isqueued() {
      return this.uploadingInfo.state === 'queued';
    },
    isUploading() {
      return this.uploadingInfo.state === 'uploading';
    },
    isUploaded() {
      return this.uploadingInfo.state === 'uploaded';
    },
    isRetry() {
      return this.uploadingInfo.state === 'retry';
    },
    isFailed() {
      return this.uploadingInfo.state === 'failed';
    },
    getProbeStatus(video) {
      if (!this.isVideoProbeCompleted(video)) {
        // if (this.isVideoProcessedOverTime(video, this.deadlineTime)) {
        //   console.log('overtime');
        //   this.probeStatus = 'overtime';
        //   return this.$t('video.upload.checkingFileOverTime');
        // }
        this.probeStatus = 'checking';
        return this.$t('video.upload.checkingFile');
      }
      if (this.isVideoProbeError(video)) {
        this.probeStatus = 'error';
        return this.$t('video.upload.uploadedFileError.fullText');
      }
      this.probeStatus = 'complete';
      return this.$t('video.upload.uploaded');
    },
    canShowCompleteState() {
      if (!this.isDisplayProbeStatus) {
        return this.isUploaded();
      }
      return this.isUploaded() && this.isVideoProbeCompleted(this.uploadItem) && !this.isVideoProbeError(this.uploadItem);
    },
    canRemoveFile() {
      return this.isqueued();
    },
    onRetryUploadVideo() {
      const videoKey = this.uploadItem.key;
      this.$store.dispatch('forceRetryUploadVideo', { videoKey });
    },
    onReuploadVideoSourceFile() {
      this.toggleReuploadModal = !this.toggleReuploadModal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  display: flex;
  align-items: center;
  padding-right: $spacing-base * 0.75;
  color: $grey-600;
  border-color: $grey-200;

  .actions {
    margin-left: auto;
    flex: 0 0 20px;
    text-align: center;
    font-size: $font-size-base * 1.25;
    cursor: pointer;
    color: $grey-500;
  }

  &:hover {
    text-decoration: none;
  }

  &.list-group-item-queued {
    opacity: 0.7;
  }

  &.list-group-item-retrying {
    .actions {

      .spinner-border {
        width: 20px;
        height: 20px;
        border-width: 2px;
        transition: all 0.5s ease-out;
      }

      .remove {
        display: none;
        transition: all 0.5s ease-out;
      }

      &:hover {
        .spinner-border {
          display: none;
        }

        .remove {
          display: flex;
        }
      }
    }
  }
}

.detail {
  flex: 0 0 300px;
  // flex: 1 0 auto;

  .title {
    margin-bottom: $spacing-base * 0.35;
    color: $grey-700;
    font-weight: $font-weight-bold;
    line-height: 1.15;
  }

  .state-item {

    &.state-retry {
      display: flex;
      .spinner-border {
        width: 14px;
        height: 14px;
        border-width: 2px;
        margin-right: 4px;
      }
    }

    &:not(.state-uploaded) {
      font-size: $font-size-base * 0.75;
    }

    &.state-uploaded {
      font-size: $font-size-base * 0.875;

      .warning-status {
        color: darken($warning, 3%);
      }
    }

  }

  .progress-container {
    margin-top: $spacing-base * 0.25;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: $grey-200;
    position: relative;

    .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $success;
      transition: all 0.2s ease-out;
    }
  }

  .progress-upload-container {
    margin-top: $spacing-base * 0.25;
    display: flex;

    .percent {
      margin-left: auto;
    }
  }

  .error {
    margin-top: $spacing-base * 0.25;
    font-size: $font-level-8;
  }
}

@media screen and (max-width: $max-layout-sm) {

  .list-group-item {
    .detail {
      flex: 1 0 auto;
    }
  }
}
</style>
