<template>
  <Teleport to="body">
    <div tabindex="0" class="modal-container" id="modal-container">
      <div
        v-if="!onClickPrimaryButton"
        class="modal-content"
        :class="modalSizeClass"
        :style="styles"
        data-test="modal-content"
      >
        <ModalCloseButton :onCloseModal="onCloseModal" />
        <ModalHeader
          v-if="isShowModalHeader"
          :title="title"
          :description="description"
        />
        <div class="modal-body" id="content-body" data-test="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
      <form
        v-else
        @submit.prevent="onClickPrimaryButton"
        class="modal-content"
        :class="modalSizeClass"
        :style="styles"
        data-test="modal-content-form"
      >
        <ModalCloseButton :onCloseModal="onCloseModal" />
        <ModalHeader
          v-if="isShowModalHeader"
          :title="title"
          :description="description"
          :isShowInLineEdit="isShowInLineEdit"
        >
          <div v-if="hasInLineEdit && title">
            <div
              v-if="!isShowInLineEdit"
              class="edit-button"
              @click="isShowInLineEdit = true">
              <i class="fas fa-pen edit-icon" />
            </div>
            <InlineEdit
              v-else
              :value="title"
              :onSubmit="onEditName"
              :onClose="onCancelEditName" />
          </div>
        </ModalHeader>
        <!-- NOTE: Don't remove id="content-body" it use by multiselect  -->
        <div class="modal-body" id="content-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="isShowModalFooter" data-test="modal-footer">
          <slot name="footer"></slot>
          <div class="modal-action" data-test="modal-action">
            <Button
              v-if="isShowSecondaryButton"
              button-classes="secondary-btn"
              type="button"
              @click.stop="secondaryButtonAction"
              :disabled="isDisabledSecondaryButton"
              :buttonStyle="secondaryButtonStyle">
              <template v-slot:content>
                {{secondaryButtonText || t('common:button.cancel')}}
              </template>
              <template v-slot:icon-prefix v-if="slots['secondary-btn-prefix']">
                <slot name="secondary-btn-prefix"></slot>
              </template>
            </Button>
            <Button
              v-if="isShowPrimaryButton"
              button-classes="primary-btn"
              type="submit"
              :disabled="isDisabledPrimaryButton"
              :buttonStyle="primaryButtonStyle"
              :isSaving="isSaving">
              <template v-slot:content>
                {{primaryButtonText || t('common:button.save')}}
              </template>
              <template v-slot:icon-prefix v-if="slots['primary-btn-prefix']">
                <slot name="primary-btn-prefix" v-if="slots['primary-btn-prefix']"></slot>
              </template>
              <!-- add icon-suffix -->
              <template v-slot:icon-suffix v-if="slots['primary-btn-suffix']">
                <slot name="primary-btn-suffix" v-if="slots['primary-btn-suffix']"></slot>
              </template>
            </Button>
          </div>
        </div>
      </form>
      <div @click="onCloseModal" class="modal-overlay"/>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import {
  computed, onBeforeUnmount, onMounted, ref, toRefs, VNode,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ModalCloseButton from '@/modules/shared/components/atoms/modalComponents/ModalCloseButton.vue';
import ModalHeader from '@/modules/shared/components/atoms/modalComponents/ModalHeader.vue';
import InlineEdit from '@/modules/shared/components/molecules/inlineEdit/InlineEdit.vue';
import type { ModalProps } from './Modal.type';

const props = withDefaults(defineProps<ModalProps>(), {
  size: 'medium',
  hasInLineEdit: false,
  isShowModalHeader: true,
  isShowModalFooter: true,
  primaryButtonStyle: 'primary',
  isShowPrimaryButton: true,
  secondaryButtonStyle: 'text-secondary',
  isShowSecondaryButton: true,
});

const {
  size,
  title,
  description,
  isShowModalHeader,
  isShowModalFooter,
  isSaving,
  hasInLineEdit,
  modalClass,
  width,
  primaryButtonText,
  primaryButtonStyle,
  isDisabledPrimaryButton,
  isShowPrimaryButton,
  onClickPrimaryButton,
  secondaryButtonText,
  secondaryButtonStyle,
  isDisabledSecondaryButton,
  isShowSecondaryButton,
  onClickSecondaryButton,
} = toRefs(props);

const emit = defineEmits<{
  modalClose: [];
  'update:title': [value: string];
}>();

/* eslint-disable func-call-spacing, no-spaced-func */
const slots = defineSlots<{
  default?: () => VNode;
  body?: () => VNode;
  footer?: () => VNode;
  'primary-btn-prefix'?: () => VNode;
  'primary-btn-suffix'?: () => VNode;
  'secondary-btn-prefix'?: () => VNode;
}>();

const { t } = useI18n();
const isShowInLineEdit = ref(false);

const modalSizeClass = computed(() => {
  if (size.value) {
    return `modal-${size.value} ${modalClass.value}`;
  }
  return modalClass.value;
});

const styles = computed(() => {
  if (width.value) {
    return {
      width: typeof width.value === 'number' ? `${width.value}px` : width.value,
    };
  }

  return undefined;
});

function onCloseModal() {
  emit('modalClose');
  document.body.classList.remove('modal-open');
}

function onEditName(name: string) {
  emit('update:title', name);
  isShowInLineEdit.value = false;
}

function onCancelEditName() {
  isShowInLineEdit.value = false;
}

function secondaryButtonAction() {
  if (onClickSecondaryButton.value) {
    onClickSecondaryButton.value();
  } else {
    emit('modalClose');
  }
}

function keyListener(e: KeyboardEvent) {
  if (e.code === 'Escape') {
    onCloseModal();
  }
}

const appendedBodyClass = 'modal-open';

onMounted(() => {
  document.body.classList.add(appendedBodyClass);
  window.addEventListener('keydown', keyListener);
});

onBeforeUnmount(() => {
  document.body.classList.remove(appendedBodyClass);
  window.removeEventListener('keydown', keyListener);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';
@import '~@/assets/scss/breakpoint.scss';

.modal-container {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  overflow: visible;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: $bg-light;
  border: 1px solid $grey-400;
  border-radius: $border-radius-5;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: relative;
}

.edit-button {
  cursor: pointer;
}

.modal-body {
  @extend .scrollbar;
  color: $grey-800;
  padding: $spacing-base $spacing-24 $spacing-24 $spacing-24;
  flex: 1 1 auto;
  max-height: 76vh;
  overflow: auto;

  > :slotted(p), > :slotted(div) {
    margin-bottom: $spacing-32;
  }

  :slotted(div.tab-container) {
    margin-bottom: $spacing-24;
  }

  > :slotted(:last-child) {
    /* margin: 0 auto; */
    margin-bottom: 0;
  }

  &.no-padding {
    padding: $spacing-base 0;
  }

  @media screen and (max-width: 1100px) {
    min-height: unset;
  }
}

.modal-footer {
  padding: $spacing-base $spacing-24;
  display: flex;
  width: 100%;
  border-top: 1px solid $grey-200;
  align-items: center;
  justify-content: space-between;

  .fa-spin {
    font-size: 10px;
  }

  .modal-action {
    margin-left: auto;
    display: flex;
    column-gap: $spacing-12;

    :deep(button) {
      white-space: nowrap;
    }
  }

  :slotted(i) {
    font-size: $icon-size-12;
  }

  > :slotted(*) {
    margin: unset; // Override Bootstrap
  }

  :slotted(.btn-remove) {
    padding-left: 0;
  }

  :slotted(.success) {
    color: $success;

    i {
      color: $success;
    }
  }

  :slotted(.info) {
    color: $info;

    i {
      color: $info;
    }
  }

  :slotted(.warning) {
    color: $warning;

    i {
      color: $warning;
    }
  }

  :slotted(.danger) {
    color: $danger;

    i {
      color: $danger;
    }
  }

  /* :deep(.button) {
    &:last-child {
      margin-left: auto;
    }
  }

  :slotted(.btn) {
    &:last-child {
      margin-left: auto;
    }
  }

  :slotted(.action-container) {
    margin-left: auto;
  } */
}

// sizing

.modal-small {
  max-width: 450px;
  width: 450px;
}

.modal-medium {
  max-width: 600px;
  width: 600px;
}

.modal-large {
  max-width: 800px;
  width: 800px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: $max-layout-sm) {
  .modal-container {
    align-items: flex-start;
    padding: $spacing-base * 1.25 $spacing-base;
    max-width: 100%;
  }

  .modal-content {
    width: 100%;
    max-width: 550px;
    position: relative;
    pointer-events: auto;
    background-color: $bg-light;
    background-clip: padding-box;

    &.modal-small {
      max-width: 450px;
    }

    &.modal-medium {
      max-width: 600px;
    }

    &.modal-large {
      max-width: 750px;
    }

    &.modal-larger {
      max-width: calc(100vw - 10%);
    }
  }

  .modal-body {
    overflow-y: auto;
    height: 100%;
  }

  .modal-header {
    // padding: $spacing-base $spacing-base * 0.5;
  }
}

@media screen and (min-width: $min-layout-sm) and (max-width: $max-layout-lg) {
  .modal-content {
    width: calc(100vw - 10vw);
    max-width: 550px;
    position: relative;
    pointer-events: auto;

    &.modal-small {
      max-width: 450px;
    }

    &.modal-medium {
      max-width: 600px;
    }

    &.modal-large {
      max-width: 750px;
    }

    &.modal-larger {
      max-width: calc(100vw - 10%);
    }
  }

  .modal-body {
    padding: $spacing-base $spacing-24 $spacing-24 $spacing-24;
    overflow-y: auto;
    height: 100%;
  }
}

@media screen and (max-width: $max-layout-xxs) {
  .modal-body {
    min-height: unset;
  }
}
</style>
