<template>
  <ConfirmModal
    size="small"
    :title="$t('recentlyDeleted.title', { videoName } )"
    :buttonTitle="$t('common:recover')"
    buttonStyle="primary"
    :onClose="onCloseModal"
    :onConfirm="recoverVideo"
    :disableConfirmButton="isRecovering"
  >
    <template v-slot:body>
      <div class="description" v-html="$t('recentlyDeleted.subtitle', { videoName } )" />
    </template>
  </ConfirmModal>
</template>

<script>
import _ from 'lodash';
import ConfirmModal from '@/modules/shared/components/organisms/confirmModal/ConfirmModal.vue';
import { restoreDeletedVideo } from '../../../services';

export default {
  props: {
    onCloseModal: {
      type: Function,
      default: () => {},
    },
    video: {
      type: Object,
      required: true,
    },
  },
  emits: ['success'],
  components: {
    ConfirmModal,
  },
  data() {
    return {
      error: null,
      isRecovering: false,
    };
  },
  computed: {
    projectKey() {
      return this.$route.params.projectKey;
    },
    videoKey() {
      return _.get(this.video, 'key', null);
    },
    videoName() {
      return _.get(this.video, 'title', '');
    },
  },
  methods: {
    async recoverVideo() {
      this.isRecovering = true;
      try {
        await restoreDeletedVideo(this.videoKey);
        this.isRecovering = false;
        this.onCloseModal();
        this.$emit('success');
      } catch (error) {
        this.error = error;
        this.isRecovering = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.page .page-header .modal-header .title {
  color: $body-color;
}
</style>
