<template>
  <ChooseProjectForUploadModal
    :onCloseModal="onCloseUploadModal"
    v-if="isChooseProjectModalShowing" />
  <FilterSlideOut
    v-if="isSlideOutToggle"
    :onClose="onCloseSlideOut" />
  <Section v-if="loadProjectVideoListState.status === 'success'">
    <div class="search-container">
      <div class="search-input-wrapper">
        <div class="form-control-search">
          <Input
            class="form-control"
            name="keyword"
            :modelValue="q"
            @change="onSearchVideo"
            isSearchInput
            :placeholder="t('videos.searchBy')"
            dataTest="input-search-videos"
          />
        </div>
        <Button type="button" @click="onOpenSlideOut" buttonStyle="secondary" dataTest="filter-videos-button">
          <template v-slot:icon-prefix>
            <i class="fa fa-filter filter-icon mr-1" />
          </template>
          <template v-slot:content>
            <span class="button-text">{{ t('common:filter') }}</span>
          </template>
        </Button>
      </div>
      <!-- upload button via project -->
      <template v-if="projectKey">
        <Button
          :url="`/${teamName}/projects/${projectKey}/upload`"
          buttonStyle="primary"
          buttonClasses="btn-upload"
          v-if="!isUploadPermissionDenied()"
          dataTest="video-upload-button"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-cloud-arrow-up"></i>
          </template>
          <template v-slot:content> {{ t('common:button.upload') }} </template>
        </Button>
      </template>
      <!-- upload button via all-projects -->
      <template v-if="!projectKey">
        <Button
          @click.stop="onChangeStateUploadModal"
          buttonStyle="primary"
          buttonClasses="btn-upload"
          v-if="!isUploadPermissionDenied()"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-cloud-arrow-up"></i>
          </template>
          <template v-slot:content> {{ t('common:button.upload') }} </template>
        </Button>
      </template>
    </div>
    <!-- There are videos in the project -->
    <DataTable
      v-if="!isEmptyProjectVideo"
      responsive-layout="b"
      class="video-list-desktop"
      data-test="videos-list-table"
    >
      <template v-slot:table-header>
        <Row>
          <Column width="140px"><HeaderCell /></Column>
          <Column minWidth="200px" isPrimaryColumn><HeaderCell :text="t('videoList.table.videoInfo.title')" /></Column>
          <Column minWidth="130px"><HeaderCell text="Video Key" /></Column>
          <Column minWidth="180px"><HeaderCell text="Geo-blocking" /></Column>
          <Column minWidth="120px"><HeaderCell :text="t('common:form.updatedAt')" /></Column>
          <Column width="140px" isActionColumn><HeaderCell /></Column>
        </Row>
      </template>
      <template v-slot:table-content>
        <VideoItem
          v-for="(video, itemIndex) in projectVideoList"
          :key="itemIndex"
          :video="getVideoFromVideosUpload(video)"
          :columnType="columnType"
        />
      </template>
    </DataTable>

    <!-- Mobile UI -->
    <div class="video-item-container video-list-mobile">
      <VideoItem
        v-for="(video, itemIndex) in projectVideoList"
        :key="itemIndex"
        :video="getVideoFromVideosUpload(video)"
        responsive
      />
    </div>

    <!-- There are NO project information at all -->
    <ErrorMessage
      v-if="isEmptyProject"
      :iconClassName="emptyProjectErrorMessage.icon"
      :title="emptyProjectErrorMessage.title"
      :description="emptyProjectErrorMessage.description"
      :buttonTitle="emptyProjectErrorMessage.buttonTitle"
      :buttonUrl="emptyProjectErrorMessage.buttonUrl"
      :buttonPrefixIconClassName="emptyProjectErrorMessage.buttonPrefixIconClassName"
    />
    <template v-if="!isEmptyProject">
      <!-- There are NO videos in the project -->
      <MessageBox
        v-if="isEmptyProjectVideo && !isFiltered"
        :iconClassName="emptyProjectVideoErrorMessage.icon"
        :title="emptyProjectVideoErrorMessage.title"
        :description="emptyProjectVideoErrorMessage.description"
        :buttonTitle="emptyProjectVideoErrorMessage.buttonTitle"
        :buttonUrl="emptyProjectVideoErrorMessage.buttonUrl"
        :buttonStyle="emptyProjectVideoErrorMessage.buttonStyle"
      />
      <!-- There are videos in the project but search not found -->
      <template v-if="isEmptyResultBySearch">
        <EmptySearch v-if="!isEmptySearch" :keyword="q" />
        <MessageBox
          v-if="!isEmptyTagIds"
          :iconClassName="emptyProjectVideoErrorMessage.icon"
          :title="emptyProjectVideoErrorMessage.title"
          :description="emptyProjectVideoErrorMessage.description"
          :buttonTitle="emptyProjectVideoErrorMessage.buttonTitle"
          :buttonUrl="emptyProjectVideoErrorMessage.buttonUrl"
          :buttonStyle="emptyProjectVideoErrorMessage.buttonStyle"
        />
      </template>
    </template>
    <template v-slot:section-footer v-if="!isEmptyProjectVideo && isShowPagination()">
      <div class="pagination-container" v-if="isShowPagination()">
        <Pagination v-if="!isEmptyProjectVideo" :lastPage="projectVideoListLastPage" />
      </div>
    </template>
  </Section>
  <!-- load error -->
  <Section v-if="loadProjectVideoListState.status === 'error'">
    <ErrorMessage
      :statusCode="loadProjectVideoListState.error?.statusCode"
      :title="loadProjectVideoListState.error?.message"
      :buttonTitle="t('response:backToHomepage')"
      :buttonUrl="`/${teamName}/projects`"
    />
  </Section>
  <PreLoaderSection v-else-if="loadProjectVideoListState.status === 'loading'" />
</template>

<script lang="ts" setup>
import { Filter } from '@/types/Axios.type';
import _ from 'lodash';
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import FilterSlideOut from '@/modules/shared/components/organisms/filterSlideOut/FilterSlideOut.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import ChooseProjectForUploadModal from '@/modules/projectVideoList/components/organisms/chooseProjectForUploadModal/ChooseProjectForUploadModal.vue';
import VideoItem from '../components/molecules/videoItem/VideoItem.vue';
import { useStore } from '../store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const columnType = ref('created');
const isSlideOutToggle = ref(false);

const currentProject = computed(() => store.state.project.currentProject);
const projectVideoList = computed(() => store.state.projectVideoList.projectVideoList);
const projectVideoListLastPage = computed(() => store.state.projectVideoList.projectVideoListLastPage);
const loadProjectVideoListState = computed(() => store.state.projectVideoList.loadProjectVideoListState);
const pageScrollPosition = computed(() => store.state.projectVideoList.pageScrollPosition);
const isChooseProjectModalShowing = computed(() => store.state.videoUpload.isChooseProjectForUploadModalShowing);
const getVideoFromVideosUpload = computed(() => store.getters.getVideoFromVideosUpload);

const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);
const projectListTotal = computed(() => store.state.projectList.projectListTotal);

const query = computed(() => route.query);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const q = computed(() => getQueryAsString(route.query, 'q') ?? '');
const tagIds = computed(() => getQueryAsString(route.query, 'tagIds') ?? '');

const isAllProjects = computed(() => route.name === 'allProjectVideos');
const isEmptyVideo = computed(() => _.isEmpty(projectVideoList.value));
const isEmptySearch = computed(() => _.isEmpty(q.value));
const isEmptyTagIds = computed(() => _.isEmpty(tagIds.value));
const isFiltered = computed(() => !isEmptySearch.value || !isEmptyTagIds.value);
const isEmptyResultBySearch = computed(() => isEmptyVideo.value && isFiltered.value);
const isEmptyProjectVideo = computed(() => isEmptyVideo.value);

const isEmptyProject = computed(() => projectListTotal.value === 0);
const isLoadProjectListStateError = computed(() => !_.isEmpty(loadProjectListState.value?.error));

function isUploadPermissionDenied() {
  return currentProject.value && !currentProject.value.actions?.upload;
}

const emptyProjectVideoErrorMessage = computed(() => {
  if (query.value.tagIds) {
    return {
      statusCode: null,
      icon: 'fas fa-search',
      title: t('common:tag.resultEmpty.title'),
      description: t('common:tag.resultEmpty.description'),
      buttonTitle: t('common:search.resultEmpty.buttonTitle'),
      buttonUrl: `/${teamName.value}/projects/${projectKey.value}`,
      buttonStyle: 'primary',
    };
  }
  if (!isUploadPermissionDenied()) {
    return {
      statusCode: null,
      icon: 'fas fa-box-open',
      title: t('project.readyToUpload.title'),
      description: t('project.readyToUpload.subTitle'),
      buttonTitle: t('common:button.upload'),
      buttonUrl: `/${teamName.value}/projects/${projectKey.value}/upload`,
    };
  }
  return {
    statusCode: null,
    icon: 'fas fa-box-open',
    title: t('project.noPermission.title'),
    description: t('video.noPermission.helpText'),
  };
});

const emptyProjectErrorMessage = computed(() => {
  if (isLoadProjectListStateError.value && loadProjectListState.value.error) {
    return {
      statusCode: loadProjectListState.value.error.statusCode,
      title: `${t('response:errorUnknown.title')}`,
      description: `${t('response:errorUnknown.helpText')}
        <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>`,
    };
  }
  return {
    title: `${t('project.notFound.title')}`,
    icon: 'fas fa-inbox',
    buttonTitle: `${t('project.create.title')}`,
    buttonUrl: `/${teamName.value}/projects/create`,
    buttonPrefixIconClassName: 'fas fa-plus',
  };
});

async function load(filter: Filter) {
  const newFilter = { ...filter, ...(projectKey.value && { projectKey: projectKey.value }) };
  await store.dispatch('loadProjectVideoList', { filter: newFilter });
  if (projectKey.value) {
    await store.dispatch('loadCollectionList', { projectKey: projectKey.value });
  }
}

function onCloseUploadModal() {
  store.dispatch('setChooseProjectForUploadModalShowing', false);
}

function onChangeStateUploadModal() {
  store.dispatch(
    'setChooseProjectForUploadModalShowing',
    !isChooseProjectModalShowing.value,
  );
}

function isShowPagination() {
  return projectVideoListLastPage.value > 1;
}

function onSearchVideo(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    router.push({
      query: { q: event.target.value },
    });
  }
}

function onToggleSlideOut() {
  isSlideOutToggle.value = !isSlideOutToggle.value;
  if (isSlideOutToggle.value) {
    document.body.classList.add('filter-sidebar-open');
  } else {
    document.body.classList.remove('filter-sidebar-open');
  }
}

function onOpenSlideOut() {
  isSlideOutToggle.value = true;
  document.body.classList.add('filter-sidebar-open');
}

function onCloseSlideOut() {
  isSlideOutToggle.value = false;
  document.body.classList.remove('filter-sidebar-open');
}

watch(query, (value) => {
  load(value);
  const pageNumber = typeof value.page === 'string' ? parseInt(value.page, 10) : 1;
  store.commit('setLastNavigatedVideoListPage', pageNumber);
}, { deep: true, immediate: true });

watch(route, onCloseUploadModal, { deep: true });

onMounted(() => {
  if (projectKey.value) {
    /** All Projects Video List page has no projectKey */
    store.dispatch('loadProjectByKey', projectKey.value);
  }

  store.dispatch('loadProjectList');
  store.dispatch('loadProjectVideoCaptionIssueList');
  store.dispatch('setChooseProjectForUploadModalShowing', false);
  store.dispatch('loadCountryList');

  if (projectKey.value || isAllProjects.value) {
    load(query.value);
  }

  if (route.query.tagIds !== undefined) {
    onToggleSlideOut();
  }

  if (pageScrollPosition.value) {
    window.scrollTo(0, pageScrollPosition.value);
  }
});

onBeforeUnmount(() => {
  if (isSlideOutToggle.value) {
    onToggleSlideOut();
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.video-cover-image-column {
  flex: 0 0 120px;
  margin-right: $spacing-base;
}

.information-wrapper {
  display: flex;
  flex: 1;
}

.action-column {
  flex: 0 0 93px;
}

:deep(.section-header) {
  margin-bottom: $spacing-base;
}

:deep(.search-input-container) {
  margin-top: 0;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-32;
}
.search-input-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  .form-control-search {
    flex: 1;
    max-width: 400px;
    margin-right: $spacing-base;
  }
}

.section-video-list {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: visible;
}

.filter-icon {
  // color: $grey-700;
  font-size: $font-size-base * 0.8;
}

.clear-search-button {
  margin-top: $spacing-base;
}

@media screen and (max-width: $max-layout-lg) {
  .action-column {
    flex: 0 0 120px;
  }
}

@media screen and (max-width: $max-layout-sm) {
  :deep(.list-header-container) {
    display: none;
  }

  :deep(.search-input-container) {
    margin-top: 1rem;
  }

  :deep(.btn-upload) {
    width: 100%;
  }

  .search-input-wrapper {
    .form-control-search {
      /* flex: 1 1 auto; */
      /* margin-right: $spacing-base; */
    }
    width: 100%;
  }
  .search-container {
    flex-direction: column-reverse;
    row-gap: $spacing-base;
  }
}

.video-list-desktop {

  :deep(.row .column) {
    &:nth-child(2) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: $max-layout-md){
    display: none;
  }
}

:deep(.section-header) {
  @media screen and (max-width: $max-layout-sm){
    padding-top: 0;
  }
}

.video-list-mobile {

  :deep(.video-item-mobile:last-child) {
    margin-bottom: 0;
  }

  @media screen and (min-width: $min-layout-md){
    display: none;
  }

}
</style>
