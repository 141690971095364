/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { GlobalState } from '@/types/Global.type';
import _ from 'lodash';

const state: GlobalState = {
  isPageLoading: true,
  lastNavigatedProjectListPage: 1,
  lastNavigatedVideoListPage: 1,
};

const defaultState = _.cloneDeep(state);

const getters = {};

const mutations = {
  SET_PAGE_LOADING(state: GlobalState, boolean: boolean) {
    state.isPageLoading = boolean;
  },
  resetGlobalState(state: GlobalState) {
    Object.assign(state, defaultState);
  },
  setLastNavigatedProjectListPage(state: GlobalState, value: number) {
    state.lastNavigatedProjectListPage = value;
  },
  setLastNavigatedVideoListPage(state: GlobalState, value: number) {
    state.lastNavigatedVideoListPage = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
