<template>
  <div ref="root" class="select-option-wrapper">
    <div class="select-option-container" data-test="select-option">
      <multiselect
        :id="id"
        :class="[{ 'has-error': isShowErrorMessage, disabled: disabled }, className]"
        :modelValue="modelValue"
        :options="options"
        :label="label"
        :multiple="false"
        :placeholder="placeholder ? placeholder : t('common:multiselect.tags.select')"
        :taggable="false"
        :disabled="disabled"
        :selectedLabel="selectedLabel ? selectedLabel : ''"
        :selectLabel="selectLabel ? selectLabel : ''"
        :deselectLabel="deselectLabel ? deselectLabel : ''"
        @select="onSelected"
        @search-change="onSearchChange"
        :open-direction="openDirection"
        :searchable="searchable"
        :ref="ref"
      >
        <template
          v-slot:singleLabel="props"
          v-if="$slots['singleLabel']">
          <slot name="singleLabel" v-bind:option="props.option"></slot>
        </template>
        <template
          v-slot:option="props"
          v-if="$slots['option']">
          <slot name="option" v-bind:option="props.option"></slot>
        </template>
        <slot></slot>
      </multiselect>
      <div class="icon-container" v-if="$slots['icon']">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeUnmount, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';

interface SelectOptionProps {
  /** According to the component usage, modelValue is literally anything. */
  modelValue: any;
  options: Array<any>;
  optionWidth?: string;
  placeholder?: string;
  /** @default 'name' */
  label?: string;
  selectedLabel?: string;
  selectLabel?: string;
  deselectLabel?: string;
  disabled?: boolean;
  isShowErrorMessage?: boolean;
  searchable?: boolean;
  /** @default 'bottom' */
  openDirection?: 'top' | 'bottom';
  id?: string;
  className?: string;
}

const props = withDefaults(defineProps<SelectOptionProps>(), {
  label: 'name',
  openDirection: 'bottom',
});

const {
  modelValue,
  options,
  optionWidth,
  placeholder,
  label,
  selectedLabel,
  selectLabel,
  deselectLabel,
  disabled,
  isShowErrorMessage,
  searchable,
  openDirection,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: any];
  'onSearch': [value: { q: string }];
}>();

const { t } = useI18n();

const root = ref<HTMLElement | null>(null);
const contentBodyResizeObserver = ref<ResizeObserver | null>(null);
const selectResizeObserver = ref<ResizeObserver | null>(null);
const contentBodyElement = ref<HTMLElement | null>(null);

function onSelected(selectedOption: any) {
  emit('update:modelValue', selectedOption);
}

function onSearchChange(q: string) {
  emit('onSearch', { q });
}

function adjustMultiSelectElementStyle() {
  const rootElement = root.value;

  if (!rootElement) {
    return;
  }

  // Need to move icon into input area because Multiselect component postion has been set to position static.
  const inputArea = rootElement.getElementsByClassName('multiselect__tags')[0];
  const chevronIcon = rootElement.getElementsByClassName('multiselect__select')[0];
  const optionsElement = rootElement.getElementsByClassName('multiselect__content-wrapper')[0];

  if (inputArea && chevronIcon) {
    inputArea.appendChild(chevronIcon);
  }
  // Set options area width equal to input area width.
  if (inputArea && optionsElement instanceof HTMLElement) {
    // options.style.width = inputArea.clientWidth > 400 ? `${inputArea.clientWidth}px` : '400px';
    optionsElement.style.width = optionWidth.value || `${inputArea.clientWidth}px`;
  }
}

function onContentBodyResize() {
  const rootElement = root.value;
  const contentBody = document.getElementById('content-body');

  if (!rootElement || !contentBody) {
    return;
  }

  if (contentBody.clientHeight < contentBody.scrollHeight) {
    rootElement.style.position = 'relative';
  } else {
    rootElement.style.position = 'static';
  }
}

function onSelectResize() {
  const rootElement = root.value;

  if (!rootElement) {
    return;
  }

  const multiselect = rootElement.getElementsByClassName('multiselect')[0];
  const optionsElement = rootElement.getElementsByClassName('multiselect__content-wrapper')[0];

  if (multiselect && optionsElement instanceof HTMLElement) {
    optionsElement.style.width = optionWidth.value || `${multiselect.clientWidth}px`;
  }

  if (optionsElement instanceof HTMLElement && optionsElement.style.width === '0') {
    optionsElement.style.width = '300px';
  }
}

onMounted(() => {
  adjustMultiSelectElementStyle();
  contentBodyElement.value = document.getElementById('content-body');
  if (contentBodyElement.value) {
    contentBodyResizeObserver.value = new ResizeObserver(onContentBodyResize);
    contentBodyResizeObserver.value.observe(contentBodyElement.value);
  }
  selectResizeObserver.value = new ResizeObserver(onSelectResize);

  if (root.value) {
    selectResizeObserver.value.observe(root.value);
  }
});

onBeforeUnmount(() => {
  if (contentBodyResizeObserver.value && contentBodyElement.value instanceof HTMLElement) {
    contentBodyResizeObserver.value.unobserve(contentBodyElement.value);
  }
  if (selectResizeObserver.value && root.value) {
    selectResizeObserver.value.unobserve(root.value);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/themes/helpers';

.multiselect {
  position: static;
  max-width: 400px;
}

.select-option-wrapper {
  width: 100%;
}

:deep(.multiselect), :deep(.multiselect__tags) {
  min-height: 37px;
  height: fit-content;
}

:deep(.multiselect__select) {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  &::before {
    margin-top: 0;
    margin-right: $spacing-8;
    position: static;
  }
}

.option__subtitle {
  display: flex;
  /* font-size: $font-level-8; */
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  /* Fix padding 6px to make select height equal to 37px */
  padding: 6px 12px;
  display: flex;
  align-items: center;
  position: relative;
}

:deep(.multiselect__single), :deep(.multiselect__input), :deep(.multiselect__placeholder) {
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  min-height: unset;
  line-height: unset;
  overflow: hidden;
  max-width: calc(100% - 16px);
  text-overflow: ellipsis;
}

:deep(.multiselect__select) {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: calc(100% - 2px);

  &::before {
    margin-top: 0;
    margin-right: $spacing-8;
    position: static;
  }
}

// Option Hover
:deep(.multiselect__option--highlight) {
  background: $ci-primary;

  &:after {
    background: $ci-primary;
  }
}

:deep(.multiselect__content-wrapper) {
  @extend .scrollbar;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: $border-radius-3;
  overflow-x: hidden;

  .multiselect__option {
    font-size: $font-size-base;
    white-space: unset;
  }

  .option__title {
    display: block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 100%;
  }
}

.select-option-container {
  border-radius: $border-radius-3;
  &.disabled {
    background-color: $grey-100;
    border: 1px solid $grey-200;
  }

  &:not(.disabled, .has-error) {
    &:focus-within {
      :deep(.multiselect__tags) {
        outline: 2px solid #dceaf9;
        border: 1px solid $ci-primary;
        border-radius: $border-radius-3;
      }
    }
  }
  /* &.has-error {
    &:focus-within {
      outline: 2px solid #f9dcdc;
      .multiselect__tags {
        border: 1px solid $danger;
        border-radius: $border-radius-3;
      }
    }
  } */
}

.has-error {
  :deep(.multiselect__tags) {
    border: 1px solid $danger;
  }
  &:focus-within {
    :deep(.multiselect__tags) {
      outline: 2px solid #f9dcdc;
      border: 1px solid $danger !important;
      border-radius: $border-radius-3;
    }
  }
}

.error-text {
  display: flex;
  align-items: center;
  color: $danger;
  font-size: $font-level-8;
}

.multi-select::v-deep .multiselect {
  :deep(.multiselect__tags) {
    // stuff
    background: red;
  }
}

:deep(.multiselect__placeholder) {
  padding-top: 0;
  padding-left: 5px;
}

:deep(.multiselect--disabled) {
  opacity: 1;

  .multiselect__single, .multiselect__tags {
    background-color: #e9ecef;
    color: #495057;
  }
}

@media screen and (max-height: 620px) {
  :deep(.multiselect__content-wrapper) {
    height: 150px !important;
  }
}

</style>
