<template>
  <div class="management-page">
    <div class="navigate" v-if="backButtonLabel && onClickBack !== null">
      <Button
        type="button"
        buttonStyle="link-secondary"
        buttonClasses="btn-back"
        @click="onClickBack">
        <template v-slot:icon-prefix>
          <i v-if="iconClassButtonBack" :class="iconClassButtonBack" />
        </template>
        {{backButtonLabel}}
      </Button>
      <div
        class="btn-current-path"
        v-if="currentPathLabel">
        {{currentPathLabel}}
      </div>
    </div>
    <div class="header-container" id="header-container">
      <div id="management-page-header" class="header" v-if="isShowHeader">
        <div class="title-container" v-if="!isShowInLineEdit">
          <h3 v-if="title" class="title">
            {{title}}
          </h3>
          <slot name="edit-button" />
        </div>
        <slot name="inline-edit" v-if="isShowInLineEdit" />
        <div class="action" v-if="primaryButtonLabel && onClickButton !== null">
          <slot name="status-label" class="status-label mr-2" />
          <Button
            type="button"
            :buttonClasses="primaryButtonClasses"
            @click="onClickButton"
            :isSaving="isSaving">
            <template v-slot:content>
              {{primaryButtonLabel}}
            </template>
          </Button>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeUnmount, onMounted, ref, toRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface ManagementPageProps {
  iconClassButtonBack?: string;
  title?: string | null;
  backButtonAction?: string | (() => void);
  backButtonLabel?: string;
  currentPathLabel?: string;
  primaryButtonLabel?: string;
  primaryButtonAction?: string | (() => (Promise<void> | void));
  /** @default 'primary' */
  primaryButtonClasses?: string;
  /** @default true */
  isShowHeader?: boolean;
  /** @default false */
  isShowInLineEdit?: boolean;
  /** @default false */
  isEnableStickyHeader?: boolean;
}

const props = withDefaults(defineProps<ManagementPageProps>(), {
  primaryButtonClasses: 'primary',
  isShowHeader: true,
  isShowInLineEdit: false,
  isEnableStickyHeader: false,
});

const {
  iconClassButtonBack,
  title,
  backButtonAction,
  backButtonLabel,
  currentPathLabel,
  primaryButtonLabel,
  primaryButtonAction,
  primaryButtonClasses,
  isShowHeader,
  isShowInLineEdit,
  isEnableStickyHeader,
} = toRefs(props);

const router = useRouter();

const isSaving = ref<boolean>(false);

function onClickBack(): void | null {
  if (typeof backButtonAction.value === 'function') {
    backButtonAction.value();
  } else if (typeof backButtonAction.value === 'string') {
    router.push(backButtonAction.value);
  }
  return null;
}

async function onClickButton(): Promise<void | null> {
  if (typeof primaryButtonAction.value === 'function') {
    isSaving.value = true;
    await primaryButtonAction.value();
    isSaving.value = false;
  } else if (typeof primaryButtonAction.value === 'string') {
    await router.push(primaryButtonAction.value);
  }

  return null;
}

function handleScrollPage(): void {
  const headerContainer = document.getElementById('header-container');
  const header = document.getElementById('management-page-header');
  const managementContent = document.getElementById('management-content');

  if (header && headerContainer && managementContent) {
    const rect = headerContainer.getBoundingClientRect();
    const { top } = rect;

    if (top <= 0) {
      header.classList.add('sticky-header');
      header.style.width = `${managementContent.clientWidth}px`;
    } else {
      header.classList.remove('sticky-header');
      header.style.width = 'auto';
    }
  }
}

onMounted(() => {
  if (isEnableStickyHeader.value) {
    window.addEventListener('scroll', handleScrollPage);
  }
});

onBeforeUnmount(() => {
  if (isEnableStickyHeader.value) {
    window.removeEventListener('scroll', handleScrollPage);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.management-page {
  min-height: calc(100vh - 196px); // fix min-height to make every page have scrollbar;
  position: relative;
  /* .header-container {
    position: relative;
  } */
  .header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-base * 0.5;

    &.sticky-header {
      position: fixed;
      top: 0;
      padding: $spacing-base;
      background: white;
      border-bottom: 1px solid $grey-200;
      z-index: 100;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    }
    // margin-bottom: $spacing-base;

    .title-container {
      display: flex;
      align-items: center;
      color: $grey-800;
      overflow: hidden;
      .title {
        white-space: nowrap;
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      ::v-slotted(.edit-button) {
        cursor: pointer;
        margin-top: $spacing-base * 0.25;
        .edit-icon {
          font-size: 16px;
        }
      }
    }

    .action {
      margin-left: auto;
      display: flex;
      align-items: center;
      column-gap: $spacing-8;
      .status-label {
        font-size: $font-size-base;
        white-space: nowrap;
        :slotted(i) {
          margin-right: $spacing-4;
        }
      }
    }
  }

  .header-container {
    height: 40px;
  }

  // .navigate :deep(.button) {
  //   padding: $spacing-base * 0.5 0;
  //   margin-top: -($spacing-base * 0.5);
  //   i {
  //     margin-right: 2px;
  //   }
  // }

  .btn-current-path {
    color: $grey-800;
    font-weight: $font-weight-bold;

    &::before {
      display: inline-block;
      padding-left: 0.25rem;
      padding-right: 0.125rem;
      color: #6c757d;
      content: "/"; // •
      font-weight: $font-weight-base;
    }
  }
}

.navigate {
  display: flex;
  margin-bottom: $spacing-4;
  align-items: center;

  :deep(.btn-back){
    line-height: 0;
  }
}
</style>
